import React, { useState, useEffect } from 'react';
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { FaQuoteRight } from "react-icons/fa";
import productsData from "./products.json";

const Carousel = () => {
	
  const [product, setProduct] = useState(productsData);
  const [index, setIndex] = useState(0);
  
    useEffect(() => {
    const lastIndex = product.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, product]);
  
  
  const length = product.length;
  const handlePrevious = () => {
    const newIndex = index - 1;
   setIndex(newIndex < 0 ? length - 1 : newIndex);
  };
  
  const handleNext = () => {
    const newIndex = index + 1;
    setIndex(newIndex >= length ? 0 : newIndex);
  };
  
  
    // autoslide, clearInterval = een cleanup functie noodzakelijk bij interval
  useEffect(() => {
    let slider = setInterval(() => {
		
      setIndex(index >= length-1 ? 0 : (index + 1));
    }, 2000);
    return () => clearInterval(slider);
  }, [index]);
  
  
 const mytest = `./images/products/${productsData[index].image[0]}`;
 const hreflink = `products#${productsData[index].id}`;
  
  return (
    <div className="carousel-container">
      <button class="prev" onClick={handlePrevious}><FiChevronLeft /></button>
	  <div className="carousel-content-wrapper">
	   <h4>{productsData[index].name}</h4>
		  <a href={hreflink}> <img src={mytest} alt={productsData[index].name} /> </a>
	    <div class="description" dangerouslySetInnerHTML={{ __html:productsData[index].description }} />
	  </div>
      <button class="next" onClick={handleNext}><FiChevronRight /></button>
    </div>
  );
};

export default Carousel;