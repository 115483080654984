import React from 'react';
function Header() {
    return (
<header>
    <div class="header-container">
        <div class="logo"><img src="./images/logo.svg" alt="classic logo"/></div>
        <nav>
            <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/products">Products</a></li>
				<li><a href="/about">About us</a></li>
                <li><a href="/contactus">Contact us</a></li>
            </ul>
        </nav>
    </div>
</header>
    );
}
export default Header;