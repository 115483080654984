import logo from './logo.svg';
import './App.css';
import Header from './header';
import Footer from './footer';
import Carousel from "./Carousel"
import { Helmet } from 'react-helmet';

import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";

import productsData from "./products.json";

// Components for other pages


const Home = () => {
    return (
	<>
      <Helmet>
	    <title>Classic Foundry Machineries | Home </title>
	 </Helmet>
	<div class="App">
	<Header />
    <div class="content">
       <Carousel>
       </Carousel>
    </div>
	<Footer />
	</div>
	 </>
    );
};


const Contact = () => {
    return (
		<>
      <Helmet>
	    <title>Classic Foundry Machineries | Contact Us </title>
	 </Helmet>
	<div class="App">
	<Header />
    <div class="content">
	   <div class="banner">
	    <div class="address">
		<ul> 
		 <li>CLASSIC FOUNDRY MACHINERIES AND AUTOMATION</li>
		 <li>2/307 J, AUTO NAGAR, KUTTAYUR</li>
		 <li>KARAMADAI 641104</li>
		 <li>COIMBATORE</li>
		 <li>GSTIN-33AAUFC2552M1Z3</li>
		 <li>Mobile : <b>95856 25135</b></li>
		 <li>Email : <b>moorthyseeliyur@gmail.com</b></li>
		 </ul>
	    </div>
	   </div>
	</div>
	<Footer />
	</div>
	</>
    );
};


export const ProductDatalist = () => {
	return (
			productsData.map((productsdata, index) => {
        return (
            <div class="product" id={productsdata.id}>
			   <h2>{productsdata.name}</h2>
			         {productsdata.image.map((image) => {
						 const imageurl= `./images/products/${image}`;
					 return <img src={imageurl} />
      })}
	  <div class="description" dangerouslySetInnerHTML={{ __html:productsdata.description }} />
            </div>
)}));};
			
const Products = () => {
    return (
		<>
      <Helmet>
	    <title>Classic Foundry Machineries | Products </title>
	 </Helmet>	
	<div class="App">
	<Header />
    <div class="content">
		<ProductDatalist />
    </div>
	<Footer />
	</div>
	</>
    );
	};


const Abouts = () => {
    return (
	<>
      <Helmet>
	    <title>Classic Foundry Machineries | Abouts </title>
	 </Helmet>
	<div class="App">
	<Header />
    <div class="content">
		<h2>Abouts Us</h2>
		<img src="./images/classic.jpg"/>
		<p> <b>CLASSIC FOUNDRY MACHINERIES</b> is one of the leading foundry product & machinery manufacturer and supplier in India. Classic Foundry Machineries and automation is located in Manchester of South India-Coimbatore and started in the year of 2024 to serve the foundry industries with automated product and machineries. Classic Foundry Machineries and automation shall strive to be the Technology Leader in foundry Industry by delivering higher value to all interested parties while adopting Safe and Environmentally friendly processes and efficient Business System. Our moto is to full fill the each and every needs of customer and make them delightful, Also we are in this field is not merely to survive, but to thrive; and to do with some passion, some forecasting and some style with innovated technology.</p>
		</div>
	<Footer />
	</div>
	</>
    );
};



function App() {
  return (
		<Router>
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/index.html" element={<Home />} />
			<Route path="products" element={<Products />} />
			<Route path="/about" element={<Abouts />} />
			<Route path="/contactus" element={<Contact />} />
		</Routes>
		</Router>
	
  );
}

export default App;
